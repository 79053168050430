import axios from '@/axios.js'

export default {
  fetchBookingChannels ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('booking-channel')
        .then(({data: response}) => {
          commit('SET_BOOKING_CHANNELS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchBookingChannel (context, bookingChannelID) {
    return new Promise((resolve, reject) => {
      axios.get(`booking-channel/${bookingChannelID}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeBookingChannel (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('booking-channel', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateBookingChannel (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`booking-channel/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyBookingChannel ({ commit }, bookingChannelID) {
    return new Promise((resolve, reject) => {
      axios.delete(`booking-channel/${bookingChannelID}`)
        .then((response) => {
          commit('REMOVE_RECORD', bookingChannelID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
